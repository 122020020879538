import { IDataExperience } from "../types/dataExperience.type";

export const dataExperience: IDataExperience[] = [
  {
    Position: "Frontend Developer",
    Company: "Telkom Indonesia",
    StartDate: "Feb 2023",
    EndDate: "Present",
    JobDescription: `Responsible for handling the Evomo platform by Telkom, one of the platforms from internal startup Telkom focused on solving problems in Industrial manufacturing with IoT (Internet of Things). 
    Develop, maintain, add features, and bug fixing OEE and EMS dashboard.`,
  },
  {
    Position: "Frontend Developer",
    Company: "Evomo",
    StartDate: "Feb 2022",
    EndDate: "Present",
    JobDescription: `Developed, maintained, added features and bug fixing dashboard Evomo using Javascript, ReactJs, Redux, NextJS, Typescript, Zustand, TailwindCSS, and Ant Design. 
    Collaborate with Web Designer, Back End Engineer and QA Engineer , etc to develop and maintain the project.`,
  },
  {
    Position: "Frontend Engineer (Freelance)",
    Company: "DOT Indonesia",
    StartDate: "Jun 2022",
    EndDate: "Jun 2023",
    JobDescription: `Developed, maintained, added features and bug fixing NFT Infinity project web app using NextJS, Typescript, Web3, TailwindCSS, and Ant Design. 
      Collaborate with Back End Engineer and QA engineer to develop and maintain the project.`,
  },
  {
    Position: "Frontend Developer (Intern)",
    Company: "Kawan Kerja",
    StartDate: "Feb 2022",
    EndDate: "Apr 2022",
    JobDescription:
      "Developed a web company profile using ReactJS, Typescript, and Styled-Components.",
  },
  {
    Position: "Frontend Engineer (Intern)",
    Company: "Endorsaja",
    StartDate: "Nov 2021",
    EndDate: "Jan 2022",
    JobDescription:
      "Developed a web admin back office for the company using ReactJS, Typescript, and Styled-Components. Helped Backend to develop REST API for web admin using Golang, and PostgreSQL.",
  },
];
