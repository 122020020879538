import {
  BootstrapLogo,
  CssLogo,
  GolangLogo,
  HtmlLogo,
  JavascriptLogo,
  NodeJsLogo,
  ReactLogo,
  TypescriptLogo,
} from "../assets";
import { ISkill } from "../types/dataSkill.type";

export const dataSkills: ISkill[] = [
  {
    name: "HTML",
    level: "Advanced",
    image: HtmlLogo,
  },
  {
    name: "CSS",
    level: "Advanced",
    image: CssLogo,
  },
  {
    name: "Javascript",
    level: "Intermediate",
    image: JavascriptLogo,
  },
  {
    name: "Bootstrap",
    level: "Intermediate",
    image: BootstrapLogo,
  },
  {
    name: "ReactJS",
    level: "Intermediate",
    image: ReactLogo,
  },
  {
    name: "Typescript",
    level: "Intermediate",
    image: TypescriptLogo,
  },
  {
    name: "NextJS",
    level: "Intermediate",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Nextjs-logo.svg/1200px-Nextjs-logo.svg.png",
  },
  {
    name: "NodeJs",
    level: "Beginner",
    image: NodeJsLogo,
  },
  {
    name: "Golang",
    level: "Beginner",
    image: GolangLogo,
  },
  {
    name: "Jest",
    level: "Beginner",
    image: "https://jestjs.io/img/opengraph.png",
  },
  {
    name: "React Native",
    level: "Beginner",
    image:
      "https://www.kindpng.com/picc/m/765-7652239_react-native-svg-logo-hd-png-download.png",
  },
  {
    name: "MongoDB",
    level: "Beginner",
    image: "https://infinapps.com/wp-content/uploads/2018/10/mongodb-logo.png",
  },
  {
    name: "Express",
    level: "Beginner",
    image:
      "https://assets.website-files.com/60b9fdcaf8c317a1cfdb2bd9/60d5df5a051d8765f7027878_expressjs.png",
  },
  {
    name: "TailwindCSS",
    level: "Intermediate",
    image: "https://miro.medium.com/max/450/1*9V4r2JpA02Jzu0Tro-i6hg.png",
  },
  {
    name: "Ant Design",
    level: "Intermediate",
    image:
      "https://gw.alipayobjects.com/zos/rmsportal/rlpTLlbMzTNYuZGGCVYM.png",
  },
  {
    name: "Sass/Scss",
    level: "Intermediate",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Sass_Logo_Color.svg/1280px-Sass_Logo_Color.svg.png",
  },
];

export const skills = [
  "HTML",
  "CSS",
  "Javascript",
  "Git",
  "React.js", 
  "Typescript", 
  "Next.js", 
  "TailwindCSS", 
  "Ant Design",
  "Redux",
  "Jest",
  "Sass/Scss",
  "Styled-Components", 
  "React Native",
  "Node.js",
  "NestJS",
  "Express.js", 
  "Prisma ORM",
  "PostgreSQL",
  "MongoDB",
  "Docker",
  "Golang", 
];
